function setEqualHeight() {
    $(".equal").matchHeight();
    $(".news-content-wrapper").matchHeight();
}

$(window).on("load",function() {
    setEqualHeight();
    $.fn.matchHeight._update();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$(window).bind('scroll', function () {
    var skelBreakpoints = skel.vars.stateId,
        scroll_limit    = 50,
        margin_top;
    if (skelBreakpoints.search("/xsmall") > -1) {
        margin_top = 0 - Math.min($(window).scrollTop(),scroll_limit);
        $("#header-wrapper").css("margin-top",margin_top + "px");
    } else {
        $("#header-wrapper").css("margin-top","");
    }
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

function responsiveTable(selector){
    var regex = /<br\s*[\/]?>/gi;
        var labels = {};
    $(selector).each(function(){
        labels = {};
        $(this).find('thead').find("th").each(function(index ){
            labels[index] = $(this).html();
        });
        $(this).find('tbody').find("tr").each(function(){
            var tr = $(this),label;
            tr.find("td").each(function(idx){
                label = labels[idx];
                label = label.replace(regex, " / ");
                $(this).attr("data-label",label);
            });
        });
    });
}

jQuery(document).ready(function($) {
    responsiveTable(".cenik-tabela");
});
